import React, { useEffect, useState, lazy, Suspense } from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import { CookiesProvider, useCookies } from "react-cookie";
import { CustomProvider } from "rsuite";
import ruRU from "rsuite/locales/ru_RU";
import Cookies from "universal-cookie";

import OverlayAndSpinner from "./components/OverlayAndSpinner/OverlayAndSpinner";

import ReportingService from "./services/ReportingService";
import UserService from "./services/UserService";

import { AppContext } from "./contexts/AppContext";
import { ROLES } from "./constants";

const FindDuplicatesPage = lazy(() => import("./pages/FindDuplicatesPage"));
const ImportDatabasePage = lazy(() => import("./pages/ImportDatabasePage"));
const MyDBsPage = lazy(() => import("./pages/MyDBsPage"));
const PreinstallPage = lazy(() => import("./pages/PreinstallPage"));
const SearchClientPage = lazy(() => import("./pages/SearchClientPage"));
const TestsPage = lazy(() => import("./pages/TestsPage"));
const MainPage = lazy(() => import("./pages/MainPage"));
const StatisticManagersPage = lazy(() =>
  import("./pages/StatisticManagersPage")
);
const StatisticBasesPage = lazy(() => import("./pages/StatisticBasesPage"));
const DBStatisticByManagersPage = lazy(() =>
  import("./pages/DBStatisticByManagersPage")
);
const DBStatisticByManagerDetailsPage = lazy(() =>
  import("./pages/DBStatisticByManagerDetailsPage")
);
const DBStatisticByClientPage = lazy(() =>
  import("./pages/DBStatisticByClientPage")
);
const StatisticTransferredClientsPage = lazy(() =>
  import("./pages/StatisticTransferredClientsPage")
);
const StatisticDashboardPage = lazy(() =>
  import("./pages/StatisticDashboardPage")
);

const UserInfoPage = lazy(() => import("./pages/UserInfoPage"));
const ManagersPage = lazy(() => import("./pages/ManagersPage"));
const ManagersGroupsPage = lazy(() => import("./pages/ManagersGroupsPage"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const ManagerWorkspacePage = lazy(() => import("./pages/ManagerWorkspacePage"));
const ScriptsPage = lazy(() => import("./pages/ScriptsPage"));
const ChatSettingsPage = lazy(() => import("./pages/ChatSettingsPage"));
const DocumentsPage = lazy(() => import("./pages/DocumentsPage"));
const EditDocumentPage = lazy(() => import("./pages/EditDocumentPage"));
const MyTestsPage = lazy(() => import("./pages/MyTestsPage"));
const PassTestPage = lazy(() => import("./pages/PassTestPage"));
const MyScriptsPage = lazy(() => import("./pages/MyScriptsPage"));
const ReportingMainPage = lazy(() => import("./pages/ReportingMainPage"));
const ReportingManagersPage = lazy(() =>
  import("./pages/ReportingManagersPage")
);
const ReportingChangePasswordPage = lazy(() =>
  import("./pages/ReportingChangePasswordPage")
);
const ManagerStatisticPage = lazy(() => import("./pages/ManagerStatisticPage"));

const FooterComponent = lazy(() =>
  import("./components/FooterComponent/FooterComponent")
);
const AddTestComponent = lazy(() =>
  import("./components/AddTestComponent/AddTestComponent")
);
const HeaderComponent = lazy(() =>
  import("./components/HeaderComponent/HeaderComponent")
);
const AddAndEditScriptComponent = lazy(() =>
  import("./components/AddAndEditScriptComponent/AddAndEditScriptComponent")
);
const EditTestComponent = lazy(() =>
  import("./components/EditTestComponent/EditTestComponent")
);
const ReportingHeaderComponent = lazy(() =>
  import("./components/ReportingHeaderComponent/ReportingHeaderComponent")
);
const IPControlPage = lazy(() => import("./pages/IPControlPage"));

const cookies = new Cookies();

/**
 * Main application component
 *
 * @component
 * @returns {JSX.Element} - main application component
 */
function App() {
  const reportingService = new ReportingService();
  const userService = new UserService();

  const start = new Date();
  start.setHours(0, 0, 0, 0);
  const end = new Date();
  end.setHours(23, 59, 59, 999);

  const [cookie] = useCookies(["user", "token"]);

  const [token, setToken] = useState(cookie.token ? cookie.token : "");
  const [user, setUser] = useState(cookie.user ? cookie.user : null);

  const [statisticsDate, setStatisticsDate] = useState([start, end]);
  const [exchangeRates, setExchangeRates] = useState(null);

  // useEffect to update body class depending on user role
  useEffect(
    function () {
      if (user && user.role === "reporting") {
        document.body.classList.remove("crm-view");
        document.body.classList.add("reporting");
      } else {
        document.body.classList.remove("reporting");
        document.body.classList.add("crm-view");
      }
    },
    [user]
  );

  // useEffect to initialize chat and fetch user data on app load
  useEffect(() => {
    if (!!user) {
      setJivoChat();
    }
    if (!!token) {
      getUserData(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * Function to set token value
   * @function
   * @param {string} newTokenValue - new token value
   */
  function setTokenValue(newTokenValue) {
    setToken(newTokenValue);
  }

  /**
   * Function to set new user
   *
   * @function
   * @param {object} newUser - new user object
   */
  function setNewUser(newUser) {
    setUser(newUser);
  }

  /**
   * Function to fetch user data from API
   *
   * @async
   * @function
   * @param {string} token
   */
  async function getUserData(token) {
    try {
      const user = await userService.getUserInformation(token);
      cookies.set("user", user.data, { path: "/" });
      setUser(user.data);
    } catch (e) {
      console.log("Error:");
      console.log(e);
      return false;
    }
  }

  /**
   * Function to render different views based on user role.
   * If `user`, show  `user.role` depended components, else show login page.
   *
   * @function
   * @param {object} user - user object
   * @returns {JSX.Element} - view based on user role
   */
  function getRoleDependedView(user) {
    if (!user) {
      return <LoginPage />;
    } else if (user.role === ROLES.ADMIN) {
      return (
        <React.Fragment>
          <OverlayAndSpinner />
          <HeaderComponent />
          <div className="container">
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/my-databases" element={<MyDBsPage />} />
              <Route
                path="/import-databases"
                element={<ImportDatabasePage />}
              />
              <Route path="/search-client" element={<SearchClientPage />} />
              <Route path="/find-duplicates" element={<FindDuplicatesPage />} />
              <Route path="/preinstall" element={<PreinstallPage />} />
              <Route path="/managers" element={<ManagersPage />} />
              <Route path="/managers-groups" element={<ManagersGroupsPage />} />
              <Route path="/access-control" element={<IPControlPage />} />
              <Route
                path="/statistics/bases"
                element={<StatisticBasesPage />}
              />
              <Route
                path="/statistics/base_by_managers/:baseId"
                element={<DBStatisticByManagersPage />}
              />
              <Route
                path="/statistics/base_by_managers/:baseId/manager/:managerId"
                element={<DBStatisticByManagerDetailsPage />}
              />
              <Route
                path="/statistics/base_by_clients/:baseId"
                element={<DBStatisticByClientPage />}
              />
              <Route
                path="/statistics/managers"
                element={<StatisticManagersPage />}
              />
              <Route
                path="/statistics/managers/:managerId"
                element={<ManagerStatisticPage />}
              />
              <Route
                path="/statistics/transferred_clients"
                element={<StatisticTransferredClientsPage />}
              />
              <Route
                path="/statistics/dashboard"
                element={<StatisticDashboardPage />}
              />
              <Route
                path="/user_info/:id"
                element={<UserInfoPage user={user} />}
              />
              <Route path="/scripts" element={<ScriptsPage />} />
              <Route
                path="/scripts/add-script"
                element={<AddAndEditScriptComponent />}
              />
              <Route
                path="/scripts/edit-script/:scriptId"
                element={<AddAndEditScriptComponent />}
              />
              <Route path="/chat-settings" element={<ChatSettingsPage />} />
              <Route path="/tests" element={<TestsPage />} />
              <Route path="/tests/add-test" element={<AddTestComponent />} />
              <Route
                path="/tests/edit-test/:testId"
                element={<EditTestComponent />}
              />
              <Route path="/documents" element={<DocumentsPage />} />
              <Route path="/documents/:docId" element={<EditDocumentPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
          <FooterComponent />
        </React.Fragment>
      );
    } else if (user.role === ROLES.MANAGER) {
      return (
        <React.Fragment>
          <OverlayAndSpinner />
          <HeaderComponent />
          <div className="container">
            <Routes>
              <Route path="/" element={<ManagerWorkspacePage />} />
              <Route path="/my-tests" element={<MyTestsPage />} />
              {!!user?.visible_statistics && (
                <Route
                  path="/statistics/managers/:managerId"
                  element={<ManagerStatisticPage />}
                />
              )}
              <Route path="/my-scripts" element={<MyScriptsPage />} />
              <Route
                path="/my-scripts/add-script"
                element={<AddAndEditScriptComponent />}
              />
              <Route
                path="/my-scripts/edit-script/:scriptId"
                element={<AddAndEditScriptComponent />}
              />
              <Route
                path="/my-tests/pass-test/:testId"
                element={<PassTestPage />}
              />
              <Route
                path="/user_info/:id"
                element={<UserInfoPage user={user} />}
              />
              <Route path="/documents" element={<DocumentsPage />} />
              <Route path="/documents/:docId" element={<EditDocumentPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
          <FooterComponent />
        </React.Fragment>
      );
    } else if (user.role === ROLES.REPORTING) {
      if (!exchangeRates) {
        (async function () {
          const res = await reportingService.getCurrentExchangeRate();
          if (res.data.status) {
            setExchangeRates(res.data.data);
          }
        })();
      }

      return (
        <React.Fragment>
          <OverlayAndSpinner />
          <ReportingHeaderComponent exchangeRates={exchangeRates} />
          <div className="container">
            <Routes>
              <Route
                path="/"
                element={<ReportingMainPage exchangeRates={exchangeRates} />}
              />
              <Route path="/managers" element={<ReportingManagersPage />} />
              <Route
                path="/change-password"
                element={<ReportingChangePasswordPage />}
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </React.Fragment>
      );
    }
  }

  /**
   * Function to set up JivoChat if conditions are met
   *
   * @function
   * @returns {function} - function to remove script from body
   */
  function setJivoChat() {
    if (
      user?.jivo_chat?.jivo_token &&
      user?.jivo_chat?.is_active &&
      user?.role === "manager"
    ) {
      const script = document.createElement("script");
      script.src = `//code.jivosite.com/widget/${user?.jivo_chat?.jivo_token}`;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        // Clean up the script when the component is unmounted
        document.body.removeChild(script);
      };
    }
  }

  // Render the application with context and routing
  return (
    <AppContext.Provider
      value={{
        token: token,
        setTokenValue: setTokenValue,
        user: user,
        setUser: setNewUser,
        statisticsDate: statisticsDate,
        setStatisticsDate: setStatisticsDate,
      }}
    >
      <CookiesProvider>
        <CustomProvider locale={ruRU}>
          <Suspense fallback={<OverlayAndSpinner />}>
            <Router>{getRoleDependedView(user)}</Router>
          </Suspense>
        </CustomProvider>
      </CookiesProvider>
    </AppContext.Provider>
  );
}

export default App;
