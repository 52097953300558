import Cookies from "universal-cookie";
import axios from "axios";
import mem from "mem"; // Memoization utility to cache function results

// Polyfill for Buffer if not available globally
global.Buffer = global.Buffer || require("buffer/").Buffer;

const cookies = new Cookies();

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

// Initialize axios instance for public API requests
const axiosPublic = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const hostName = window.location.host;

// Create a separate axios instance for payment API
export const axiosPayment = axios.create({
  baseURL: "https://api-nameserve.growgold.xyz/", // Base URL for payment API
  redirect: "follow",
  headers: {
    "Content-type": "application/json",
    "x-host-name": hostName,
  },
});

/**
 * Function to refresh the user token
 *
 * @async
 * @function
 * @returns {Promise<string>} - New token
 */
const refreshTokenFn = async () => {
  const token = cookies.get("token");

  try {
    const response = await axiosPublic.post(
      "/auth/refresh",
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const newToken = response?.data?.access_token;

    // If no new token is returned, clear cookies and redirect to login
    if (!newToken) {
      cookies.remove("token", { path: "/" });
      cookies.remove("user", { path: "/" });
      cookies.remove("token_expires_in", { path: "/" });
      window.location.href = "/";
    }

    cookies.set("token", newToken, { path: "/" });
    console.log("Token refreshed!");

    return newToken;
  } catch (error) {
    cookies.remove("token", { path: "/" });
    cookies.remove("user", { path: "/" });
    cookies.remove("token_expires_in", { path: "/" });
    window.location.href = "/";
  }
};

// Maximum age for cached tokens (10 seconds)
const maxAge = 10000;

// Memoize the token refresh function to avoid repeated calls within maxAge
const memoizedRefreshToken = mem(refreshTokenFn, { maxAge });

// Request interceptor to attach the authorization token to requests
axios.interceptors.request.use(
  async (request) => {
    const token = cookies.get("token");
    if (token) {
      request.headers.common.Authorization = token;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token expiration and refresh it if necessary
axios.interceptors.response.use(
  async (response) => {
    if (response?.data?.error === "Unauthenticated.") {
      cookies.remove("token", { path: "/" });
      cookies.remove("user", { path: "/" });
      cookies.remove("token_expires_in", { path: "/" });
      window.location.href = "/";
      return;
    }

    // Check if request was made with an authorization token
    if (!response?.config?.headers?.Authorization) {
      cookies.remove("token", { path: "/" });
      cookies.remove("user", { path: "/" });
      cookies.remove("token_expires_in", { path: "/" });
      return response;
    }

    // Decode the token to check expiration
    const token = cookies.get("token");
    const buff = Buffer.from(token.split(".")[1], "base64"); // Decode token
    const tokenCreationStamp = JSON.parse(buff.toString()).nbf * 1000; // Token creation time
    const tokenExpiresIn = cookies.get("token_expires_in") * 1000; // Expiration time from cookies
    const now = Date.now(); // Current time
    const timeLeft = tokenExpiresIn - (now - tokenCreationStamp); // Time left for token expiration

    // If the token is expired, clear cookies and redirect to login
    if (timeLeft < 0) {
      cookies.remove("token", { path: "/" });
      cookies.remove("user", { path: "/" });
      cookies.remove("token_expires_in", { path: "/" });
      window.location.href = "/";
      return;
    }
    // If the token is about to expire (less than 1/3rd time left), refresh it
    else if (timeLeft < tokenExpiresIn / 3) {
      const result = await memoizedRefreshToken(); // Call memoized refresh function
      if (result) {
        const newToken = `Bearer ${result}`;
        cookies.set("token", newToken, { path: "/" });
      } else {
        console.log("Failed refresh!!");
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });
        cookies.remove("token_expires_in", { path: "/" });
        window.location.href = "/";
      }
    }

    return response;
  },
  async (error) => {
    const config = error?.config;
    // If the error status is 401 (Unauthorized) and the request has not been sent before, clear cookies
    if (error?.response?.status === 401 && !config?.sent) {
      cookies.remove("token", { path: "/" });
      cookies.remove("user", { path: "/" });
      cookies.remove("token_expires_in", { path: "/" });
    }
    return Promise.reject(error);
  }
);

export default axios;
